import { FC, useState } from 'react';
import { useElements, PaymentElement } from '@stripe/react-stripe-js';
import { Modal, useAlert, ContentLoader } from '@weave/design-system';
import useStore from '@forms-exp/store';
import { PaymentsApi } from '@forms-exp/api';
import { STRIPE_RESPONSE_DELAY } from '../../../../payment-fields.constant';
import DummyAch from './dummy-ach/dummy-ach.component';

interface PaymentAchProps {
  fieldId: string;
  onAchSaved: () => void;
  onCancel: () => void;
}

const PaymentAch: FC<PaymentAchProps> = ({ fieldId, onAchSaved, onCancel }) => {
  const alert = useAlert();
  const [isSaving, setIsSaving] = useState(false);
  const elements = useElements();
  const {
    stripe,
    paymentSetupLinks,
    submissionToken,
    locationId,
    person,
    updateStripeACHDetails,
    previewMode,
  } = useStore();

  async function saveAchOnFileHandler() {
    if (previewMode) {
      onAchSaved();
      return;
    }

    if (!person || !person.id) {
      alert.error('Person not found!');
      return;
    }

    if (!stripe) {
      alert.error('Stripe not initialized!');
      return;
    }

    const paymentSetupLink = paymentSetupLinks[fieldId];
    if (!paymentSetupLink) {
      alert.error('Payment setup link not found!');
      return;
    }

    if (!elements) {
      alert.error('Stripe Elements not initialized!');
      return;
    }

    setIsSaving(true);
    const { error: submitError } = await elements.submit();
    if (submitError) {
      alert.error(
        submitError.message ||
          'Error submitting details to Stripe. Please review information and try again.'
      );
      setIsSaving(false);
      return;
    }

    const result = await stripe.confirmSetup({
      elements,
      clientSecret: paymentSetupLink,
      redirect: 'if_required',
      confirmParams: {
        return_url: `${window.location.origin}/form?company_id=${locationId}&qwe=${submissionToken}`,
      },
    });

    if (result.error) {
      switch (result.error.code) {
        default:
          alert.error(`Unable to save ACH. Please try again. ${result.error.code}`);
          break;
      }

      setIsSaving(false);
      return;
    }

    const paymentMethodId = result.setupIntent.payment_method as string;
    setTimeout(async () => {
      const paymentMethods = await PaymentsApi.getPaymentMethods({
        locationId,
        personId: person.id || '',
        submissionId: submissionToken,
        paymentMethodIds: [paymentMethodId],
      });
      const paymentMethod = paymentMethods.find(
        (method) => method.paymentMethodId === paymentMethodId
      );

      if (!paymentMethod) {
        alert.error('Payment method not found!');
        setIsSaving(false);
        return;
      }

      const accountType = paymentMethod.bankAccountInfo?.type || '';
      const lastFour = paymentMethod.bankAccountInfo?.lastFour || '';
      const bankName = paymentMethod.bankAccountInfo?.bankName || '';
      const created = paymentMethod.created || '';
      if (!accountType || !bankName || !created || !lastFour) {
        alert.error('Unable to get ACH details!');
        setIsSaving(false);
        return;
      }

      updateStripeACHDetails(fieldId, {
        accountType,
        bankName,
        created,
        lastFour,
      });
      setIsSaving(false);
      onAchSaved();
    }, STRIPE_RESPONSE_DELAY);
  }

  return (
    <>
      <Modal.Body>{previewMode ? <DummyAch /> : <PaymentElement />}</Modal.Body>
      <Modal.Actions
        primaryLabel="Save"
        onPrimaryClick={saveAchOnFileHandler}
        disablePrimary={isSaving}
        secondaryLabel="Cancel"
        onSecondaryClick={onCancel}
        disableSecondary={isSaving}
      />
      <ContentLoader show={isSaving} message="Saving ACH..." />
    </>
  );
};

export default PaymentAch;
