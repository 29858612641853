import {
  EnvironmentVariables,
  EnvironmentVariableProps,
  FORMS_ENV_PROP_KEY,
} from '@forms-exp/types';

let _isClientSideEnvSet = false;

/**
 * This can be used in the client side and server side.
 */
export let environmentVariables: EnvironmentVariables = {
  baseApiUrl: process.env.NEXT_PUBLIC_FORMS_BASE_URL,
  adobeJSApiKey: process.env.NEXT_PUBLIC_ADOBE_JS_API_KEY,
  environment: process.env.NEXT_PUBLIC_ENVIRONMENT,
  sentryDSN: process.env.NEXT_PUBLIC_SENTRY_DSN,
  stripeKey: process.env.NEXT_PUBLIC_STRIPE_KEY,
  stripeCardOnlyPaymentMethodConfig:
    process.env.NEXT_PUBLIC_STRIPE_CARD_ONLY_PAYMENT_METHOD_CONFIG,
  stripeACHOnlyPaymentMethodConfig:
    process.env.NEXT_PUBLIC_STRIPE_ACH_ONLY_PAYMENT_METHOD_CONFIG,
};

export function isClientSideEnvSet() {
  return _isClientSideEnvSet;
}

/**
 * This function is to be used in the server side only.
 * @returns {EnvironmentVariables}
 */
export function getEnvProps(): EnvironmentVariableProps {
  return {
    [FORMS_ENV_PROP_KEY]: {
      baseApiUrl: process.env.NEXT_PUBLIC_FORMS_BASE_URL,
      adobeJSApiKey: process.env.NEXT_PUBLIC_ADOBE_JS_API_KEY,
      environment: process.env.NEXT_PUBLIC_ENVIRONMENT,
      sentryDSN: process.env.NEXT_PUBLIC_SENTRY_DSN,
      stripeKey: process.env.NEXT_PUBLIC_STRIPE_KEY,
      stripeCardOnlyPaymentMethodConfig:
        process.env.NEXT_PUBLIC_STRIPE_CARD_ONLY_PAYMENT_METHOD_CONFIG,
      stripeACHOnlyPaymentMethodConfig:
        process.env.NEXT_PUBLIC_STRIPE_ACH_ONLY_PAYMENT_METHOD_CONFIG,
    },
  };
}

/**
 * This function is to be used in the client side only.
 */
export function setClientSideEnv(envProps: EnvironmentVariables) {
  environmentVariables = { ...envProps };
  _isClientSideEnvSet = true;
}
