import { FC } from 'react';
import { FormFieldOptions } from '@forms-exp/types';
import { ChecklistField } from '@weave/design-system';

export const InlineChecklistField: FC<{
  fieldProps: any;
  optionsToShow: FormFieldOptions[];
  fieldId: string;
}> = ({ fieldProps, optionsToShow, fieldId }) => (
  <ChecklistField label="" {...fieldProps}>
    {optionsToShow.map(
      (option) =>
        option.value && (
          <ChecklistField.Option
            key={`${fieldId}-opt-${option.value}`}
            name={option.value}
          >
            {option.label}
          </ChecklistField.Option>
        )
    )}
  </ChecklistField>
);
