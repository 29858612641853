import { FC, useState, useEffect } from 'react';
import DOMPurify from 'dompurify';

// Regex to detect if the content is from Quill editor
const QUILL_CONTENT_REGEX = / class="[^ql-]*ql-/gm;

interface RichTextFieldProps {
  value: string;
}

const RichTextField: FC<RichTextFieldProps> = ({ value }) => {
  const [sanitizedValue, setSanitizedValue] = useState('');
  const [hasQuillContent, setHasQuillContent] = useState(false);

  useEffect(() => {
    // Check if the content is from Quill editor.
    if (QUILL_CONTENT_REGEX.test(value)) {
      setHasQuillContent(true);
    }

    const _sanitizedValue = DOMPurify.sanitize(value, {
      USE_PROFILES: { html: true },
    });
    setSanitizedValue(_sanitizedValue);
  }, [value]);

  return (
    /**
     * The `section` tag is used to wrap the content of the rich text field.
     * The `ql-editor` class will otherwise take the entire height of the page and mess up the layout.
     */
    <section>
      <div className={hasQuillContent ? 'ql-editor' : undefined}>
        <pre
          className="rich-text-container"
          dangerouslySetInnerHTML={{
            __html: sanitizedValue,
          }}
        ></pre>
      </div>
    </section>
  );
};

export default RichTextField;
