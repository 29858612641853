import { FC } from 'react';
import { Text, PlusIcon, Heading, NakedButton } from '@weave/design-system';
import { containerStyle } from './add-payment-method-placeholder-button.styles';

interface AddPaymentMethodPlaceholderProps {
  headingLabel: string;
  buttonLabel: string;
  onClick: () => void;
}

const AddPaymentMethodPlaceholder: FC<AddPaymentMethodPlaceholderProps> = ({
  buttonLabel,
  headingLabel,
  onClick,
}) => {
  return (
    <>
      <Heading level={3}>{headingLabel}</Heading>
      <NakedButton css={containerStyle} onClick={onClick}>
        <PlusIcon />
        <Text color="primary" weight="bold">
          {buttonLabel}
        </Text>
      </NakedButton>
    </>
  );
};

export default AddPaymentMethodPlaceholder;
