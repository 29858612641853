import axios from 'axios';
import {
  FieldState,
  PatronInfoFieldStates,
  FormTemplateData,
  FormExperienceFormTypes,
  FormTemplateDataResponse,
  DataKey,
  SubmissionMode,
  SubmissionDocumentType,
  PDFFieldState,
  CardOnFileFieldState,
} from '@forms-exp/types';
import { getTodayISODate, PersonPMSDetails, base64ToPDFFile } from '@forms-exp/helpers';
import { environmentVariables } from '@forms-exp/env';
import { getISODate } from '../';
import { PDFUploadResponse, uploadFile, UploadFileType } from './uploadFile';

interface FormSubmissionPayload {
  forms: FormTemplateData[];
  fieldState: Record<string, FieldState>;
  primaryFieldState: Partial<Record<DataKey, FieldState>>;
  patronInfoFields: PatronInfoFieldStates;
  formToken: string;
  patronSearchDetails: PersonPMSDetails | undefined;
  companyToken: string;
  formType?: FormExperienceFormTypes;
  mode?: SubmissionMode;
}

interface ImageUploadMap {
  front: string;
  back: string;
}

interface UploadedFiles {
  success: boolean;
  uploadedFiles: UploadedFile[];
  imageUploadMap: Record<string, ImageUploadMap>;
  signatureImageUploadMap: Record<string, string>;
  pdfUploadMap: Record<string, string>;
}

let alreadUploadedData: UploadedFiles | undefined = undefined;

export async function submitForm({
  forms,
  fieldState,
  primaryFieldState,
  patronInfoFields,
  formToken,
  companyToken,
  formType,
  mode,
  patronSearchDetails,
}: FormSubmissionPayload): Promise<
  | {
      success: true;
      submissionId: string;
      submittedFormData: string;
    }
  | {
      success: false;
      message: string;
    }
> {
  const URL = `${environmentVariables.baseApiUrl}/forms-digital/v1/submit-form`;
  const isSolicitedForm = formType === null;

  const {
    success: imageUploadSuccess,
    uploadedFiles,
    imageUploadMap,
    signatureImageUploadMap,
    pdfUploadMap,
  } = await uploadFiles({
    fieldState,
    companyId: companyToken,
    primaryFieldState,
  });

  if (!imageUploadSuccess) {
    return { success: false, message: 'Failed to upload all the images!' };
  }

  const { forSubmission } = processFields({
    forms,
    fieldState,
    imageUploadMap,
    primaryFieldState,
    signatureImageUploadMap,
    pdfUploadMap,
  });

  let submissionData: Record<string, any> = {
    forms: forSubmission,
    type: SubmissionDocumentType.UNKNOWN,
    company_id: companyToken,
    person_details: {
      first_name: '',
      last_name: '',
      email: '',
      phone_number: '',
      birthdate: '',
      street_address_1: '',
      street_address_2: '',
      city: '',
      state: '',
      zip_code: '',
    },
    attachment_details: [],
  };

  // We want to change the mode in future
  // See what all modes are supported in the backend
  if (isSolicitedForm) {
    submissionData.submission_id = formToken;
  } else {
    submissionData.document_id = formToken;
  }

  if (mode) {
    submissionData.mode = mode;
  }

  if (uploadedFiles.length > 0) {
    submissionData.attachment_details = uploadedFiles;
  }

  if (formType) {
    switch (formType) {
      case 'Form':
        submissionData.type = SubmissionDocumentType.FORMS;
        break;

      case 'Packet':
        submissionData.type = SubmissionDocumentType.PACKET;
        break;
    }

    submissionData = {
      ...submissionData,
      person_details: {
        ...submissionData.person_details,
        first_name: patronInfoFields.firstName.value,
        last_name: patronInfoFields.lastName.value,
        email: patronInfoFields.email.value,
        phone_number: patronInfoFields.mobilePhone.value,
        birthdate: getISODate(patronInfoFields.birthdate.value),
        street_address_1: patronInfoFields.address1.value,
        street_address_2: patronInfoFields.address2.value,
        city: patronInfoFields.city.value,
        state: patronInfoFields.state.value,
        zip_code: patronInfoFields.postalCode.value,
      },
    };

    // kiosk mode for person self check-in
    if (patronSearchDetails && mode == 5) {
      submissionData = {
        ...submissionData,
        person_details: {
          ...submissionData.person_details,
          first_name: patronSearchDetails?.firstName,
          last_name: patronSearchDetails?.lastName,
          phone_number: patronSearchDetails?.mobilePhone,
          birthdate: getISODate(patronSearchDetails?.birthdate),
          email: patronSearchDetails?.email || '',
          patient_pms_id: patronSearchDetails?.patientPmsId || '',
          source_tenant_id: patronSearchDetails?.sourceTenantId || '',
          street_address_1: patronSearchDetails?.address1 || '',
          street_address_2: patronSearchDetails?.address2 || '',
          city: patronSearchDetails?.city || '',
          state: patronSearchDetails?.state || '',
          zip_code: patronSearchDetails?.postalCode || '',
        },
      };
    }
  }

  try {
    const { data, status } = await axios.post(URL, submissionData, {
      headers: { 'Content-Type': 'application/json' },
    });

    if (status === 200) {
      const submissionId = formType ? data?.submissionId : formToken;
      alreadUploadedData = undefined;

      return {
        success: true,
        submissionId,
        submittedFormData: JSON.stringify(submissionData.form),
      };
    }

    return { success: false, message: 'Form submission failed!' };
  } catch (err) {
    console.log('Error submitting the form: ', err);
    return { success: false, message: 'Error submitting the form!' };
  }
}

interface UploadImagesParams {
  fieldState: Record<string, FieldState>;
  primaryFieldState: Partial<Record<DataKey, FieldState>>;
  companyId: string;
}

enum MediaFileType {
  IMAGE = 'image',
  PDF = 'pdf',
}

interface UploadedFile {
  type: MediaFileType;
  id: string;
  name: string;
}

async function uploadFiles({
  fieldState,
  companyId,
  primaryFieldState,
}: UploadImagesParams): Promise<UploadedFiles> {
  const APIsInProgress: Array<ReturnType<typeof uploadFile>> = [];

  for (const fieldKey in fieldState) {
    const field = fieldState[fieldKey];
    const dataKey = field.dataKey;

    const fieldLabel = field.label ?? '';
    const shouldNotUseFieldLabel = fieldLabel.length === 0 || fieldLabel.length > 30;

    if (field.type === 'cardCapture') {
      let frontImageValue = field.value.front;
      let backImageValue = field.value.back;

      if (field.isPrimaryField && dataKey) {
        const primaryField = primaryFieldState[dataKey];

        if (primaryField?.type === 'cardCapture') {
          frontImageValue = primaryField.value.front;
          backImageValue = primaryField.value.back;
        }
      }

      if (frontImageValue) {
        const fileName = shouldNotUseFieldLabel ? 'Card capture' : fieldLabel;
        APIsInProgress.push(
          uploadFile({
            fieldId: fieldKey,
            file: frontImageValue,
            companyId,
            type: 'front',
            fileType: UploadFileType.IMAGE,
            label: fileName,
          })
        );
      }

      if (backImageValue) {
        const fileName = shouldNotUseFieldLabel ? 'Card capture' : fieldLabel;
        APIsInProgress.push(
          uploadFile({
            fieldId: fieldKey,
            file: backImageValue,
            companyId,
            type: 'back',
            fileType: UploadFileType.IMAGE,
            label: fileName,
          })
        );
      }
    }

    if (field.type === 'eSign' && ['image', 'wet'].includes(field.value.type)) {
      const signatureImage = field.value.data as File;
      const fileName = shouldNotUseFieldLabel ? 'E-signature' : fieldLabel;

      APIsInProgress.push(
        uploadFile({
          fieldId: fieldKey,
          file: signatureImage,
          companyId,
          fileType: UploadFileType.IMAGE,
          label: fileName,
        })
      );
    }

    if (field.type === 'pdf') {
      const pdfField = field as PDFFieldState;
      const fileName = fieldLabel.length === 0 ? 'PDF attachment' : fieldLabel;

      if (pdfField.requiresSignature && pdfField.signedPDF) {
        // Upload the signed PDF file.
        const pdfFile = base64ToPDFFile(pdfField.signedPDF, `${fieldKey}.pdf`);
        APIsInProgress.push(
          uploadFile({
            fieldId: fieldKey,
            file: pdfFile,
            companyId,
            fileType: UploadFileType.PDF,
            label: fileName,
          })
        );
      } else {
        const promise = new Promise<PDFUploadResponse>((resolve) => {
          // Add the original PDF as attachment as it is not modified.
          resolve({
            success: true,
            fieldId: fieldKey,
            fileId: pdfField.attachmendId,
            fileType: UploadFileType.PDF,
            label: fileName,
          });
        });

        APIsInProgress.push(promise);
      }
    }
  }

  if (APIsInProgress.length === 0) {
    return {
      success: true,
      uploadedFiles: [],
      imageUploadMap: {},
      signatureImageUploadMap: {},
      pdfUploadMap: {},
    };
  }

  try {
    const uploadedFiles: UploadedFile[] = [];
    const imageUploadMap: Record<string, ImageUploadMap> = {};
    const signatureImageUploadMap: Record<string, string> = {};
    const pdfUploadMap: Record<string, string> = {};
    const responses = await Promise.all(APIsInProgress);
    let allImagesUploaded = true;

    responses.forEach(({ fieldId, fileId, type, success, label }) => {
      switch (fieldState[fieldId].type) {
        case 'cardCapture':
          if (!imageUploadMap[fieldId]) {
            imageUploadMap[fieldId] = { front: '', back: '' };
          }

          if (fileId && type) {
            uploadedFiles.push({
              type: MediaFileType.IMAGE,
              id: fileId,
              name: label,
            });
            imageUploadMap[fieldId][type] = fileId;
          }
          break;

        case 'eSign':
          if (fileId) {
            signatureImageUploadMap[fieldId] = fileId;
            uploadedFiles.push({
              type: MediaFileType.IMAGE,
              id: fileId,
              name: label,
            });
          }
          break;

        case 'pdf':
          if (fileId) {
            pdfUploadMap[fieldId] = fileId;
            uploadedFiles.push({
              type: MediaFileType.PDF,
              id: fileId,
              name: label,
            });
          }
          break;
      }

      allImagesUploaded = allImagesUploaded && success;
    });

    const uploadedData = {
      success: allImagesUploaded,
      uploadedFiles: uploadedFiles,
      imageUploadMap,
      signatureImageUploadMap,
      pdfUploadMap,
    };

    // temporary store the uploaded data to avoid multiple uploads
    alreadUploadedData = uploadedData;
    return uploadedData;
  } catch (err) {
    return {
      success: false,
      uploadedFiles: [],
      imageUploadMap: {},
      signatureImageUploadMap: {},
      pdfUploadMap: {},
    };
  }
}

interface ProcessFieldParams {
  forms: FormTemplateData[];
  fieldState: Record<string, FieldState>;
  primaryFieldState: Partial<Record<DataKey, FieldState>>;
  imageUploadMap: Record<string, ImageUploadMap>;
  signatureImageUploadMap: Record<string, string>;
  pdfUploadMap: Record<string, string>;
}

function processFields({
  forms,
  fieldState,
  imageUploadMap,
  primaryFieldState,
  signatureImageUploadMap,
  pdfUploadMap,
}: ProcessFieldParams): {
  forSubmission: FormTemplateDataResponse[];
} {
  const processedFormsForSubmission: FormTemplateDataResponse[] = [];

  for (const formData of forms) {
    const formCopyForSubmission: FormTemplateDataResponse = {
      ...formData,
      fields: {},
    };

    for (const fieldId in formData.fields) {
      const field = fieldState[fieldId];
      const isPrimaryField = field.isPrimaryField;
      const dataKey = field.dataKey;
      let fieldValueToSubmit = field.value;

      if (isPrimaryField && dataKey) {
        const primaryFieldValue = primaryFieldState[dataKey]?.value;
        fieldValueToSubmit =
          primaryFieldValue !== undefined ? primaryFieldValue : fieldValueToSubmit;
      }

      // Convert the 'options' field to string for submission.
      if (
        formData.fields[fieldId].options &&
        typeof formData.fields[fieldId].options !== 'string'
      ) {
        formCopyForSubmission.fields[fieldId] = {
          ...formData.fields[fieldId],
          options: JSON.stringify(formData.fields[fieldId].options),
        };
      } else {
        formCopyForSubmission.fields[fieldId] = {
          ...formData.fields[fieldId],
          options: undefined,
        };
      }

      if (field.type === 'cardCapture' && imageUploadMap[fieldId]) {
        formCopyForSubmission.fields[fieldId] = {
          ...formCopyForSubmission.fields[fieldId],
          value: imageUploadMap[fieldId],
        };
      } else {
        formCopyForSubmission.fields[fieldId] = {
          ...formCopyForSubmission.fields[fieldId],
          value: fieldValueToSubmit,
        };
      }

      if (field.type === 'eSign' && ['image', 'wet'].includes(field.value.type)) {
        formCopyForSubmission.fields[fieldId] = {
          ...formCopyForSubmission.fields[fieldId],
          value: {
            ...formCopyForSubmission.fields[fieldId].value,
            data: signatureImageUploadMap[fieldId],
          },
        };
      }

      if (field.type === 'signature') {
        // Add the checkbox field value of the signature field to the
        // submission data.
        formCopyForSubmission.fields[fieldId] = {
          ...formCopyForSubmission.fields[fieldId],
          agrees_value: fieldState[`${fieldId}-checkbox`].value as boolean,
        };

        // Remove the 'agreesValue' field for submission.
        if (formCopyForSubmission.fields[fieldId].agreesValue) {
          delete formCopyForSubmission.fields[fieldId].agreesValue;
        }
      }

      // Format the date field values from DD/MM/yyyy to yyyy-MM-DD hh:mm:ss
      if (field.type === 'datePicker') {
        formCopyForSubmission.fields[fieldId] = {
          ...formCopyForSubmission.fields[fieldId],
          value: getISODate(fieldValueToSubmit as string),
        };
      }

      if (field.type === 'currentDatePopulator') {
        const isoDateFormat = getTodayISODate();
        const timeFormat = new Date().toLocaleTimeString([], {
          hour12: false, //enables 24 hour format
        });

        formCopyForSubmission.fields[fieldId] = {
          ...formCopyForSubmission.fields[fieldId],
          value: `${isoDateFormat} ${timeFormat}`,
        };
      }

      if (field.type === 'pdf') {
        const pdfField = field as PDFFieldState;
        const uploadedMediaId = pdfUploadMap[fieldId];

        formCopyForSubmission.fields[fieldId] = {
          ...formCopyForSubmission.fields[fieldId],
          value: uploadedMediaId ?? pdfField.attachmendId,
        };
      }

      if (field.type === 'cardOnFile') {
        const cardOnFileField = field as CardOnFileFieldState;
        const { expiryMonth, expiryYear, lastFour, nameOnCard, country } =
          cardOnFileField.value;
        formCopyForSubmission.fields[fieldId] = {
          ...formCopyForSubmission.fields[fieldId],
          value: {
            country,
            expiry: expiryMonth && expiryYear ? `${expiryMonth}/${expiryYear}` : '',
            last4: lastFour,
            nameOnCard,
          },
        };
      }

      // Convert the 'value' field to string for submission.
      if (typeof formCopyForSubmission.fields[fieldId].value !== 'string') {
        formCopyForSubmission.fields[fieldId].value = JSON.stringify(
          formCopyForSubmission.fields[fieldId].value
        );
      }

      // Remove the 'placeholder' field for submission.
      if (formCopyForSubmission.fields[fieldId].placeholder) {
        delete formCopyForSubmission.fields[fieldId].placeholder;
      }

      // Remove the 'maxLength' field for submission.
      if (formCopyForSubmission.fields[fieldId].maxLength) {
        delete formCopyForSubmission.fields[fieldId].maxLength;
      }

      // Remove the 'name' field for submission.
      if (formCopyForSubmission.fields[fieldId].name) {
        delete formCopyForSubmission.fields[fieldId].name;
      }

      // Remove the 'type' field for submission.
      if (formCopyForSubmission.fields[fieldId].type) {
        delete formCopyForSubmission.fields[fieldId].type;
      }

      // Remove the 'options' field if it's value is undefined.
      if (formCopyForSubmission.fields[fieldId].options === undefined) {
        delete formCopyForSubmission.fields[fieldId].options;
      }
    }

    // Remove the 'sectionTemplate' field for submission.
    for (const sectionId in formData.sections) {
      if (formData.sections[sectionId].sectionTemplate) {
        formCopyForSubmission.sections[sectionId] = { ...formData.sections[sectionId] };
        delete formCopyForSubmission.sections[sectionId].sectionTemplate;
      }
    }

    processedFormsForSubmission.push(formCopyForSubmission);
  }

  return {
    forSubmission: processedFormsForSubmission,
  };
}
