import { FC } from 'react';
import {
  FormRow,
  TextField,
  DropdownField,
  useFormField,
  BannerNotification,
} from '@weave/design-system';
import { bannerStyle } from '../../../../../payment-fields.styles';

const DummyPaymentCard: FC = () => {
  const cardHolderNameProps = useFormField({ type: 'text' });
  const cardNumberProps = useFormField({ type: 'text' });
  const expiryDateProps = useFormField({ type: 'text' });
  const securityCodeProps = useFormField({ type: 'text' });
  const countryProps = useFormField({ type: 'dropdown' });

  return (
    <>
      <BannerNotification
        status="info"
        message="This is just a preview of the fields"
        css={bannerStyle}
      />
      <form>
        <FormRow>
          <TextField
            {...cardHolderNameProps}
            name="card-holder-name"
            hasNonFloatingLabel
            label="Cardholder Name"
          />
        </FormRow>
        <FormRow>
          <TextField
            {...cardNumberProps}
            name="card-number"
            hasNonFloatingLabel
            label="Card Number"
          />
        </FormRow>
        <FormRow>
          <TextField
            {...expiryDateProps}
            name="expiry-date"
            hasNonFloatingLabel
            label="Expiry Date"
          />
          <TextField
            {...securityCodeProps}
            name="security-code"
            hasNonFloatingLabel
            label="Security Code"
          />
        </FormRow>
        <FormRow>
          <DropdownField
            {...countryProps}
            name="country"
            hasNonFloatingLabel
            label="Country"
            value="US"
          >
            <DropdownField.Option value="Can">Canada</DropdownField.Option>
            <DropdownField.Option value="US">United States</DropdownField.Option>
          </DropdownField>
        </FormRow>
      </form>
    </>
  );
};

export default DummyPaymentCard;
