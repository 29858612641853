import { useModalControl, ModalControlResponse } from '@weave/design-system';
import PaymentAchModal from './payment-ach-modal.component';

interface UsePaymentAchModalResults {
  PaymentAchModal: typeof PaymentAchModal;
  paymentAchModalControls: ModalControlResponse;
}

export const usePaymentCardModal = (): UsePaymentAchModalResults => {
  const modalControls = useModalControl();

  return {
    paymentAchModalControls: modalControls,
    PaymentAchModal,
  };
};
