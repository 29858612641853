import { FC } from 'react';
import { Text } from '@weave/design-system';
import { FormFieldOptions } from '@forms-exp/types';
import { InlineChecklistField } from './inline-checklist-field/inline-checklist-field.component';
import { DropdownChecklistField } from './dropdown-checklist-field/dropdown-checklist-field.component';

const DEFAULT_OPTIONS: FormFieldOptions[] = [];

interface CustomChecklistFieldProps {
  fieldProps: any; // TODO: Add proper type
  label: string;
  options: FormFieldOptions[] | undefined;
  fieldId: string;
}

const CustomChecklistField: FC<CustomChecklistFieldProps> = ({
  fieldProps,
  label,
  options = DEFAULT_OPTIONS,
  fieldId,
}) => {
  const shouldShowDropdown = options?.length > 10;

  return (
    <div>
      <Text weight="regular">{label}</Text>
      {!shouldShowDropdown ? (
        <InlineChecklistField
          fieldProps={fieldProps}
          optionsToShow={options}
          fieldId={fieldId}
        />
      ) : (
        <DropdownChecklistField
          fieldProps={fieldProps}
          options={options}
          fieldId={fieldId}
        />
      )}
    </div>
  );
};

export default CustomChecklistField;
