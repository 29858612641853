import { css } from '@emotion/core';
import { theme } from '@weave/theme';

export const multipleItemsLabelContainerStyle = css`
  display: flex;
  align-items: center;
  flex: 1;
`;

export const labelTextStyle = css`
  color: ${theme.colors.text.subdued};
  margin-left: auto;
`;

export const searchFieldStyle = css`
  margin-bottom: ${theme.spacing(1)};
`;

export const buttonContainerStyle = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const textButtonStyle = css`
  color: ${theme.colors.text.interactive};
  font-weight: ${theme.font.weight.bold};
`;

const listItemStyle = css`
  padding: ${theme.spacing(0, 1)};
`;

interface GetListItemStyleConfig {
  isFirst: boolean;
  isLast: boolean;
}

export function getListItemStyle({ isFirst, isLast }: GetListItemStyleConfig) {
  if (isFirst) {
    return [
      listItemStyle,
      css`
        padding-top: ${theme.spacing(1)};
      `,
    ];
  }

  if (isLast) {
    return [
      listItemStyle,
      css`
        padding-bottom: ${theme.spacing(1)};
      `,
    ];
  }

  return listItemStyle;
}
