import { css } from '@emotion/core';
import { useRouter } from 'next/router';
import { ChangeEvent, FC, useCallback, useEffect, useMemo, useRef } from 'react';
import {
  CheckboxField,
  DatePickerField,
  EmailField,
  MultiselectField,
  OptionSwitchField,
  PhoneField,
  PostalCodeField,
  SearchableListbox,
  Text,
  TextField,
  TextareaField,
  normalizeChangeHandler,
  useModalControl,
} from '@weave/design-system';
import { theme } from '@weave/theme';
import { useBranchingLogic } from '@forms-exp/hooks';
import useStore from '@forms-exp/store';
import { getInputFieldStyle } from '@forms-exp/styles';
import {
  ButtonStyle,
  ConditionMapper,
  FormTemplateData,
  PDFFieldState,
} from '@forms-exp/types';
import { getFormattedLabel, getFieldMaxLength } from '@forms-exp/utils';
import { ButtonField } from './button-field/button-field';
import CardCaptureField from './card-capture-field/card-capture-field.component';
import ESignatureField from './e-signature-field/e-signature-field.component';
import { InfoChangeConfirmation } from './info-change-confirmation';
import PDFField from './pdf-field/pdf-field.component';
import SignatureField from './signature-field/signature-field.component';
import SocialSecurityNumberField from './ssn-field/ssn-field.component';
import { CardOnFileField, AchOnFileField } from './payment-fields';
import RichTextField from './rich-text-field/rich-text-field.component';
import CustomChecklistField from './checklist-field/checklist-field.component';
import CustomRadiolistField from './radiolist-field/radiolist-field.component';
import CustomDropdownField from './dropdown-field/dropdown-field.component';
import {
  checkboxStyle,
  containerStyle,
  maxLengthStyle,
  optionSwitchStyle,
  searchListBoxStyles,
} from './form-elements.styles';

interface FormElementsProps {
  form: FormTemplateData;
  fieldsToShow: string[];
  reviewMode?: boolean;
  conditionMapper?: ConditionMapper;
  onBlurInput?: () => void;
  onFocusInput?: () => void;
  onChangeInput?: () => void;
}

type ConfirmationType = 'phone' | 'first_name' | 'last_name' | 'dob';

const FormElements: FC<FormElementsProps> = ({
  fieldsToShow,
  form,
  reviewMode,
  onBlurInput,
  onFocusInput,
  onChangeInput,
}) => {
  const {
    fields: fieldState,
    primaryFields: primaryFieldState,
    prePopulatedFields,
    patronInfoFields,
    handleFieldBlur,
    handleFieldFocus,
    updateFieldValue,
    validateSection,
  } = useStore();

  const router = useRouter();

  const { modalProps, triggerProps } = useModalControl({
    disableReturnFocus: true,
  });

  const { doConditionCheck } = useBranchingLogic(form);

  const lastUpdatedField = useRef<null | string>(null);

  const phoneNumberField = useRef<HTMLElement>();
  const firstNameField = useRef<HTMLElement>();
  const lastNameField = useRef<HTMLElement>();
  const dobField = useRef<HTMLElement>();

  const infoChangeConfirmationType = useRef<ConfirmationType>('phone');

  const valueChangeConfirmationModalAsked = useRef<Record<ConfirmationType, boolean>>({
    phone: false,
    first_name: false,
    last_name: false,
    dob: false,
  });

  // hook to do conditional check after state update
  // This can't be done in onchange handler because we won't have latest state changes in isConditionSatisfied function
  useEffect(() => {
    if (
      lastUpdatedField.current &&
      form.fields[lastUpdatedField.current]?.condition_ids
    ) {
      doConditionCheck(lastUpdatedField.current);
      lastUpdatedField.current = null;
    }
  }, [fieldState, doConditionCheck, form.fields]);

  // Inspired by the design-system.
  const handlers = useMemo(
    () => ({
      onBlur: (event: FocusEvent) => {
        if (!event) return;

        handleFieldBlur((event.target as any).name);

        if (onBlurInput) {
          onBlurInput();
        }
      },
      onFocus: (event: FocusEvent) => {
        if (!event) return;

        handleFieldFocus((event.target as any).name);

        const field = fieldState[(event.target as any).name];

        // show modal when phone number field is focused and pre-populated
        if (field.isPrimaryField) {
          if (
            field.dataKey === 'mobilePhone' &&
            prePopulatedFields['phoneNumber'] &&
            !valueChangeConfirmationModalAsked.current['phone']
          ) {
            infoChangeConfirmationType.current = 'phone';
            phoneNumberField.current = event.target as HTMLElement;
            valueChangeConfirmationModalAsked.current['phone'] = true;
            triggerProps.onClick();
          }
          if (
            field.dataKey === 'firstName' &&
            prePopulatedFields['firstName'] &&
            !valueChangeConfirmationModalAsked.current['first_name']
          ) {
            infoChangeConfirmationType.current = 'first_name';
            firstNameField.current = event.target as HTMLElement;
            valueChangeConfirmationModalAsked.current['first_name'] = true;
            triggerProps.onClick();
          }

          if (
            field.dataKey === 'lastName' &&
            prePopulatedFields['lastName'] &&
            !valueChangeConfirmationModalAsked.current['last_name']
          ) {
            infoChangeConfirmationType.current = 'last_name';
            lastNameField.current = event.target as HTMLElement;
            valueChangeConfirmationModalAsked.current['last_name'] = true;
            triggerProps.onClick();
          }
          // DOB won't work this way, it's a date picker and the datepicker doesn't pass any event
          // we can't determine which field has the focus, so we need to handle it separately
          // The implementation is in the onChange handler
        }

        if (onFocusInput) {
          onFocusInput();
        }
      },
      onChange: (e: any) => {
        const { name, value } = (e.target || e) as any;
        const field = fieldState[name];

        // show modal when phone number field is changed and pre-populated
        if (
          field.isPrimaryField &&
          field.dataKey === 'birthdate' &&
          prePopulatedFields['birthdate'] &&
          !valueChangeConfirmationModalAsked.current['dob']
        ) {
          normalizeChangeHandler(updateFieldValue)({
            value: patronInfoFields.birthdate.value,
            name,
          });
          infoChangeConfirmationType.current = 'dob';
          dobField.current = e.target as HTMLElement;
          valueChangeConfirmationModalAsked.current['dob'] = true;
          triggerProps.onClick();
        } else {
          normalizeChangeHandler(updateFieldValue)(e);
          // store latest updated field id in ref, to do conditional check if any
          lastUpdatedField.current = name;

          if (onChangeInput) {
            onChangeInput();
          }
        }
      },
    }),
    [
      prePopulatedFields,
      handleFieldBlur,
      handleFieldFocus,
      updateFieldValue,
      onBlurInput,
      onFocusInput,
      onChangeInput,
    ]
  );

  // Inspired by the design-system.
  // Modified for use with the card-capture field.
  const cardCaptureHandlers = useMemo(
    () => ({
      onChange: (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
          const fieldName = event.target.name as string;
          // Id will be like "<uuid>-<'front' | 'back'>"
          const idSplit = (event.target.id as string).split('-');
          const valueType = idSplit[idSplit.length - 1]; // 'front' | 'back'
          const file = event.target.files[0];

          updateFieldValue({
            name: fieldName,
            value: { [valueType]: file },
          });

          if (onChangeInput) {
            onChangeInput();
          }
        }
      },
      onRemove: (fieldName: string, valueType: 'front' | 'back') => {
        updateFieldValue({
          name: fieldName,
          value: { [valueType]: null },
        });

        if (onChangeInput) {
          onChangeInput();
        }
      },
    }),
    [updateFieldValue, onChangeInput]
  );

  const listBoxHandlers = useMemo(
    () => ({
      onSelect: (e: any) => {
        const { name, value } = (e.target || e) as any;
        // @ts-ignore
        normalizeChangeHandler(updateFieldValue({ name, value }));
        // store latest updated field id in ref, to do conditional check if any
        lastUpdatedField.current = name;

        if (onChangeInput) {
          onChangeInput();
        }
      },
    }),
    [updateFieldValue, onChangeInput]
  );

  const onInfoChangeConfirmation = useCallback((confirmationType: ConfirmationType) => {
    setTimeout(() => {
      if (confirmationType === 'phone') {
        phoneNumberField.current?.focus();
      } else if (confirmationType === 'first_name') {
        firstNameField.current?.focus();
      } else if (confirmationType === 'last_name') {
        lastNameField.current?.focus();
      } else if (confirmationType === 'dob') {
        dobField.current?.focus();
      }
    }, 0);
  }, []);

  // Inspired by the design-system.
  function getFieldProps(fieldName: string): any {
    const { type, validator, ...rest } = fieldState[fieldName];
    const {
      isPrimaryField,
      dataKey,
      value: fieldStateValue,
      error: fieldStateError,
    } = rest;
    let value = fieldStateValue;
    let error = fieldStateError;

    if (isPrimaryField && dataKey) {
      const primaryField = primaryFieldState[dataKey];
      const primaryFieldValue = primaryField?.value;
      const primaryFieldError = primaryField?.error;
      value = primaryFieldValue !== undefined ? primaryFieldValue : fieldStateValue;
      error = primaryFieldError !== undefined ? primaryFieldError : fieldStateError;
    }

    if (type === 'cardCapture') {
      return {
        id: fieldName,
        name: fieldName,
        ...rest,
        ...cardCaptureHandlers,
        active: rest.active.toString(), // To avoid HTML attr boolean warning
        touched: rest.active.toString(), // To avoid HTML attr boolean warning
        value,
        error,
      };
    }

    if (type === 'eSign') {
      return {
        id: fieldName,
        name: fieldName,
        ...rest,
        active: rest.active.toString(), // To avoid HTML attr boolean warning
        touched: rest.active.toString(), // To avoid HTML attr boolean warning
        value,
        error,
      };
    }

    if (type === 'searchable-listbox') {
      return {
        id: fieldName,
        name: fieldName,
        ...rest,
        ...listBoxHandlers,
        value,
        error,
      };
    }

    return {
      id: fieldName,
      name: fieldName,
      ...rest,
      ...handlers,
      value,
      error,
    };
  }

  return (
    <div css={containerStyle}>
      <InfoChangeConfirmation
        modalProps={modalProps}
        type={infoChangeConfirmationType.current}
        onConfirm={onInfoChangeConfirmation}
      />
      {fieldsToShow.map((fieldName) => {
        const fieldProps = getFieldProps(fieldName);

        const { outterLabel, innerLabel } = getFormattedLabel({
          label: form.fields[fieldName].label,
          isRequired: fieldProps.required,
          isPreview: router.pathname === '/form-preview',
        });

        const fieldMaxLength = getFieldMaxLength({
          fieldType: fieldState[fieldName].type,
          dataKey: fieldState[fieldName].dataKey,
        });

        const fieldStyle = getInputFieldStyle({
          hasError: fieldProps.error !== '',
          hasValue: fieldProps.value !== '',
        });

        if (fieldState[fieldName].hidden) return null;

        switch (fieldState[fieldName].type) {
          case 'button':
            if (reviewMode) return null;

            return (
              <ButtonField
                id={fieldName}
                label={form.fields[fieldName].label}
                buttonStyle={form.fields[fieldName].button_style as ButtonStyle}
                onClick={() => {
                  doConditionCheck(fieldName);
                }}
              />
            );

          case 'text-with-button':
            if (reviewMode) {
              return (
                <div key={`review-${fieldName}`}>
                  <Text>{form.fields[fieldName].label}</Text>
                </div>
              );
            }
            return (
              <div
                css={css`
                  display: flex;
                  align-items: center;
                  gap: ${theme.spacing(5)};
                `}
              >
                <Text>{form.fields[fieldName].label}</Text>
                <div
                  css={css`
                    flex: 1;
                    white-space: nowrap;
                  `}
                >
                  <ButtonField
                    id={fieldName}
                    label={form.fields[fieldName].button_meta.label!}
                    buttonStyle={{
                      align: 'right',
                      type: form.fields[fieldName].button_meta.type!,
                    }}
                    onClick={() => {
                      doConditionCheck(fieldName);
                    }}
                  />
                </div>
              </div>
            );

          case 'cardCapture':
            return (
              <CardCaptureField
                key={fieldName}
                reviewMode={reviewMode}
                {...fieldProps}
                label={innerLabel || outterLabel}
              />
            );

          case 'checkbox':
            if (reviewMode) {
              return (
                <div key={`review-${fieldName}`}>
                  <Text weight="regular">{innerLabel || outterLabel}</Text>
                  <Text weight="bold">{fieldProps.value ? 'Yes' : 'No'}</Text>
                </div>
              );
            }

            return (
              <CheckboxField
                key={fieldName}
                css={checkboxStyle}
                {...fieldProps}
                label={innerLabel || outterLabel}
              />
            );

          case 'checklist':
            if (reviewMode) {
              const selectedOptions: string[] = [];

              form.fields[fieldName].options?.forEach((option) => {
                if (fieldProps.value.indexOf(option.value) !== -1) {
                  selectedOptions.push(option.label || '');
                }
              });

              return (
                <div key={`review-${fieldName}`}>
                  <Text weight="regular">{innerLabel || outterLabel}</Text>
                  <Text weight="bold">
                    {selectedOptions.length ? selectedOptions.join(', ') : '-'}
                  </Text>
                </div>
              );
            }

            if (!form.fields[fieldName].options?.length) {
              return null;
            }

            return (
              <CustomChecklistField
                key={fieldName}
                fieldId={fieldName}
                fieldProps={fieldProps}
                label={innerLabel || outterLabel}
                options={form.fields[fieldName].options}
              />
            );

          case 'currentDatePopulator':
            const currentDate = new Date().toLocaleDateString('en-US', {
              month: '2-digit',
              year: 'numeric',
              day: '2-digit',
            }); // Generates MM/DD/YYYY
            if (reviewMode) {
              return (
                <div key={`review-${fieldName}`}>
                  <Text weight="regular">{innerLabel || outterLabel}</Text>
                  <Text weight="bold">{currentDate}</Text>
                </div>
              );
            }

            return (
              <div key={fieldName}>
                <Text weight="regular">{innerLabel || outterLabel}</Text>
                <Text>{currentDate}</Text>
              </div>
            );

          case 'datePicker':
            if (reviewMode) {
              return (
                <div key={`review-${fieldName}`}>
                  <Text weight="regular">{innerLabel || outterLabel}</Text>
                  <Text weight="bold">{fieldProps.value || '-'}</Text>
                </div>
              );
            }

            return (
              <div key={fieldName}>
                <Text weight="regular">{innerLabel || outterLabel}</Text>
                <DatePickerField
                  label=""
                  {...fieldProps}
                  css={[
                    css`
                      max-width: 100% !important;
                    `,
                    fieldStyle,
                  ]}
                />
              </div>
            );

          case 'dropdown':
            if (reviewMode) {
              const selectedOption =
                form.fields[fieldName].options?.find(
                  (option) => option.value === fieldProps.value
                )?.label || '';

              return (
                <div key={`review-${fieldName}`}>
                  <Text weight="regular">{innerLabel || outterLabel}</Text>
                  <Text weight="bold">{selectedOption || '-'}</Text>
                </div>
              );
            }

            return (
              <CustomDropdownField
                key={fieldName}
                fieldId={fieldName}
                fieldProps={fieldProps}
                label={innerLabel || outterLabel}
                options={form.fields[fieldName].options}
                fieldStyle={fieldStyle}
              />
            );

          case 'email':
            if (reviewMode) {
              return (
                <div key={`review-${fieldName}`}>
                  <Text weight="regular">{innerLabel || outterLabel}</Text>
                  <Text weight="bold">{fieldProps.value || '-'}</Text>
                </div>
              );
            }

            return (
              <div key={fieldName}>
                <Text weight="regular">{innerLabel || outterLabel}</Text>
                <EmailField label="" {...fieldProps} css={fieldStyle} />
              </div>
            );

          case 'eSign':
            if (reviewMode) {
              return (
                <div key={`review-${fieldName}`}>
                  <Text weight="regular">{innerLabel || outterLabel}</Text>
                  <ESignatureField {...fieldProps} reviewMode={reviewMode} />
                </div>
              );
            }

            return (
              <div key={fieldName}>
                <Text weight="regular">{innerLabel || outterLabel}</Text>
                <ESignatureField {...fieldProps} />
              </div>
            );

          case 'searchable-listbox':
            if (reviewMode) {
              const selectedOptions: string[] = [];

              form.fields[fieldName].options?.forEach((option) => {
                if (fieldProps.value.indexOf(option.value) !== -1) {
                  selectedOptions.push(option.label || '');
                }
              });

              return (
                <div key={`review-${fieldName}`}>
                  <Text weight="regular">{innerLabel || outterLabel}</Text>
                  <Text weight="bold">
                    {selectedOptions.length ? selectedOptions.join(', ') : '-'}
                  </Text>
                </div>
              );
            }

            if (!form.fields[fieldName].options?.length) {
              return null;
            }

            return (
              <div key={fieldName}>
                <Text weight="regular">{innerLabel || outterLabel}</Text>
                <SearchableListbox
                  css={searchListBoxStyles}
                  {...fieldProps}
                  onSelect={(value) => {
                    fieldProps.onSelect({ name: fieldProps.name, value });
                  }}
                >
                  {form.fields[fieldName].options?.map(({ label, value }) => {
                    // Don't show options that don't have a value to select
                    if (!value) {
                      return null;
                    }

                    return (
                      <SearchableListbox.Option
                        key={`${fieldName}-opt-${value}`}
                        value={value}
                      >
                        {label}
                      </SearchableListbox.Option>
                    );
                  })}
                </SearchableListbox>
              </div>
            );

          case 'multiselect':
            if (reviewMode) {
              const selectedOptions: string[] = [];

              form.fields[fieldName].options?.forEach((option) => {
                if (fieldProps.value.indexOf(option.value) !== -1) {
                  selectedOptions.push(option.label || '');
                }
              });

              return (
                <div key={`review-${fieldName}`}>
                  <Text weight="regular">{innerLabel || outterLabel}</Text>
                  <Text weight="bold">
                    {selectedOptions.length ? selectedOptions.join(', ') : '-'}
                  </Text>
                </div>
              );
            }

            if (!form.fields[fieldName].options?.length) {
              return null;
            }

            return (
              <div key={fieldName}>
                <Text weight="regular">{innerLabel || outterLabel}</Text>
                <MultiselectField {...fieldProps} label="Select all that apply">
                  {form.fields[fieldName].options?.map(({ label, value }) => {
                    // Don't show options that don't have a value to select
                    if (!value) {
                      return null;
                    }

                    return (
                      <MultiselectField.Option
                        key={`${fieldName}-opt-${value}`}
                        value={value}
                      >
                        {label}
                      </MultiselectField.Option>
                    );
                  })}
                </MultiselectField>
              </div>
            );

          case 'optionswitch':
            if (reviewMode) {
              const highlightSelectedOption =
                form.fields[fieldName].options?.length === 2 &&
                fieldProps.value === 'Yes';
              return (
                <div key={`review-${fieldName}`}>
                  <Text weight="regular">{innerLabel || outterLabel}</Text>
                  <Text
                    weight="bold"
                    {...(highlightSelectedOption && { className: 'highlight-option' })}
                  >
                    {fieldProps.value || '-'}
                  </Text>
                </div>
              );
            }

            return (
              <div key={fieldName}>
                <Text weight="regular">{innerLabel || outterLabel}</Text>
                <OptionSwitchField {...fieldProps} css={optionSwitchStyle}>
                  {form.fields[fieldName].options?.map(({ label, value }) => {
                    // Don't show options that don't have a value to select
                    if (!value) {
                      return null;
                    }

                    return (
                      <OptionSwitchField.Option
                        key={`${fieldName}-opt-${value}`}
                        value={value}
                      >
                        {label}
                      </OptionSwitchField.Option>
                    );
                  })}
                </OptionSwitchField>
              </div>
            );

          case 'phone':
            if (reviewMode) {
              return (
                <div key={`review-${fieldName}`}>
                  <Text weight="regular">{innerLabel || outterLabel}</Text>
                  <Text weight="bold">{fieldProps.value || '-'}</Text>
                </div>
              );
            }

            return (
              <div key={fieldName}>
                <Text weight="regular">{innerLabel || outterLabel}</Text>
                <PhoneField label="" {...fieldProps} css={fieldStyle} />
              </div>
            );

          case 'postalCode':
            if (reviewMode) {
              return (
                <div key={`review-${fieldName}`}>
                  <Text weight="bold">{innerLabel}</Text>
                  <Text weight="bold">{fieldProps.value || '-'}</Text>
                </div>
              );
            }

            return (
              <div key={fieldName}>
                <Text weight="regular">{innerLabel || outterLabel}</Text>
                <PostalCodeField label="" {...fieldProps} css={fieldStyle} />
              </div>
            );

          case 'radio':
            if (reviewMode) {
              const selectedOption =
                form.fields[fieldName].options?.find(
                  (option) => option.value === fieldProps.value
                )?.label || '';

              const highlightSelectedOption =
                form.fields[fieldName].options?.length === 2 && selectedOption === 'Yes';

              return (
                <div key={`review-${fieldName}`}>
                  <Text weight="regular">{innerLabel || outterLabel}</Text>
                  <Text
                    weight="bold"
                    {...(highlightSelectedOption && { className: 'highlight-option' })}
                  >
                    {selectedOption || '-'}
                  </Text>
                </div>
              );
            }

            return (
              <CustomRadiolistField
                key={fieldName}
                fieldId={fieldName}
                fieldProps={fieldProps}
                label={innerLabel || outterLabel}
                options={form.fields[fieldName].options}
              />
            );

          case 'richText':
            return <RichTextField key={fieldName} value={form.fields[fieldName].value} />;

          case 'signature':
            if (reviewMode) {
              return (
                <div key={`review-${fieldName}`}>
                  <Text weight="regular">{innerLabel || outterLabel}</Text>
                  <Text weight="bold">
                    {fieldProps.value
                      ? `${fieldProps.value} (Digital Signature (name or initials))`
                      : '-'}
                  </Text>
                </div>
              );
            }

            const signatureInputProps = {
              label: form.fields[fieldName].label,
              ...fieldProps,
            };

            const signatureCheckboxProps = {
              label:
                form.fields[fieldName].confirmationLabel ||
                'I consent to signing this document electronically',
              ...getFieldProps(`${fieldName}-checkbox`),
            };

            return (
              <SignatureField
                key={fieldName}
                inputProps={signatureInputProps}
                checkboxProps={signatureCheckboxProps}
              />
            );

          case 'ssn':
            if (reviewMode) {
              return (
                <div key={`review-${fieldName}`}>
                  <Text weight="regular">{innerLabel || outterLabel}</Text>
                  <Text weight="bold">{fieldProps.value || '-'}</Text>
                </div>
              );
            }

            return (
              <div key={fieldName}>
                <Text weight="regular">{innerLabel || outterLabel}</Text>
                <SocialSecurityNumberField label="" {...fieldProps} css={fieldStyle} />
              </div>
            );

          case 'text':
            if (reviewMode) {
              return (
                <div key={`review-${fieldName}`}>
                  <Text weight="regular">{innerLabel || outterLabel}</Text>
                  <Text weight="bold">{fieldProps.value || '-'}</Text>
                </div>
              );
            }

            return (
              <div key={fieldName}>
                <Text weight="regular">{innerLabel || outterLabel}</Text>
                <TextField
                  {...fieldProps}
                  label=""
                  css={fieldStyle}
                  maxLength={fieldMaxLength}
                />
              </div>
            );

          case 'textarea':
            if (reviewMode) {
              return (
                <div key={`review-${fieldName}`}>
                  <Text weight="regular">{innerLabel || outterLabel}</Text>
                  <Text weight="bold" as="pre">
                    {fieldProps.value || '-'}
                  </Text>
                </div>
              );
            }

            return (
              <div key={fieldName}>
                <Text weight="regular">{innerLabel || outterLabel}</Text>
                <TextareaField
                  {...fieldProps}
                  label=""
                  autoGrow={[2, 5]}
                  css={fieldStyle}
                  maxLength={fieldMaxLength}
                />
                <Text color="subdued" size="small" css={maxLengthStyle}>
                  {fieldMaxLength - fieldProps.value.length} / {fieldMaxLength}
                </Text>
              </div>
            );

          case 'pdf': {
            const pdfFieldState = fieldState[fieldName] as PDFFieldState;
            const hasSignedPDF = !!pdfFieldState.signedPDF;
            const base64Data = pdfFieldState.signedPDF ?? pdfFieldState.value;

            if (reviewMode) {
              return null;
            }

            return (
              <PDFField
                key={fieldName}
                fieldId={fieldName}
                base64Data={base64Data}
                isEditable={pdfFieldState.requiresSignature && !hasSignedPDF}
                onCloseModal={() => {
                  validateSection([fieldName]);
                }}
              />
            );
          }

          case 'cardOnFile':
            return (
              <CardOnFileField
                key={fieldName}
                reviewMode={reviewMode}
                {...fieldProps}
                fieldId={fieldName}
                label={innerLabel || outterLabel}
              />
            );

          case 'achOnFile':
            return (
              <AchOnFileField
                key={fieldName}
                reviewMode={reviewMode}
                {...fieldProps}
                fieldId={fieldName}
                label={innerLabel || outterLabel}
              />
            );

          default:
            return <div key={fieldName}>Coming Soon</div>;
        }
      })}
    </div>
  );
};

export default FormElements;
