import { FC, useMemo, useState, useEffect, useCallback } from 'react';
import { FormFieldOptions } from '@forms-exp/types';
import {
  Text,
  ChecklistField,
  SearchField,
  Chip,
  TextButton,
  useDebouncedValue,
  useFormField,
} from '@weave/design-system';
import { usePopoverDropdown } from '@forms-exp/hooks';
import useStore from '@forms-exp/store';
import { Virtuoso } from 'react-virtuoso';
import {
  labelTextStyle,
  multipleItemsLabelContainerStyle,
  buttonContainerStyle,
  textButtonStyle,
  searchFieldStyle,
  getListItemStyle,
} from '../checklist-field.styles';
import { getHeight } from '../util';

interface DropdownChecklistFieldProps {
  fieldProps: any;
  options: FormFieldOptions[];
  fieldId: string;
}

export const DropdownChecklistField: FC<DropdownChecklistFieldProps> = ({
  fieldProps,
  options,
  fieldId,
}) => {
  const [optionsToShow, setOptionsToShow] = useState<FormFieldOptions[]>([]);
  const searchFieldProps = useFormField({
    type: 'text',
  });
  const debouncedSearchValue = useDebouncedValue(searchFieldProps.value);
  const dataKey = fieldProps.dataKey;
  const { PopoverDropdown, popoverDropdownProps } = usePopoverDropdown();
  const { updateFieldValue } = useStore();

  // Filter options based on search value
  useEffect(() => {
    if (debouncedSearchValue) {
      const filteredOptions = options?.filter(({ label }) => {
        // Don't show options that don't have a label when searching
        if (!label) {
          return false;
        }

        return label.toLowerCase().includes(debouncedSearchValue.toLowerCase());
      });
      setOptionsToShow(filteredOptions);
    } else {
      setOptionsToShow(options);
    }
  }, [debouncedSearchValue, options]);

  function getLabel() {
    const selectedValues = fieldProps.value as string[];
    if (!selectedValues || selectedValues.length === 0) {
      return 'Select';
    }

    const firstSelectedOption = options.find(
      (option) => option.value === selectedValues[0]
    );
    if (!firstSelectedOption) {
      return 'Select';
    }

    const chip = <Chip variant="outline">{firstSelectedOption.label}</Chip>;
    if (selectedValues.length === 1) {
      return chip;
    }

    return (
      <div css={multipleItemsLabelContainerStyle}>
        {chip}
        <Text size="medium" css={labelTextStyle}>
          {' '}
          +{selectedValues.length - 1} more
        </Text>
      </div>
    );
  }

  const searchBoxPlaceholder = useMemo(() => {
    switch (dataKey) {
      case 'allergies':
        return 'Search for Allergies...';
      case 'medications':
        return 'Search for Medications...';
      case 'disease':
        return 'Search for Diseases...';
      default:
        return 'Search...';
    }
  }, [dataKey]);

  function selectAllHandler() {
    const allValues = optionsToShow.map((option) => option.value);
    updateFieldValue({
      name: fieldProps.name,
      value: allValues,
      skipSetterValue: true,
    });
  }

  function clearHandler() {
    let newValue: string[] = [];
    if (debouncedSearchValue !== '') {
      // Remove the options that are in the search results
      newValue = (fieldProps.value as string[]).filter(
        (value) => !optionsToShow.map((option) => option.value).includes(value)
      );
    }

    updateFieldValue({
      name: fieldProps.name,
      value: newValue,
      skipSetterValue: true,
    });
  }

  const keyDownHandler = useCallback((e: React.KeyboardEvent) => {
    // Prevent "Enter" key from submitting the form
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  }, []);

  return (
    <div onKeyDown={keyDownHandler}>
      <PopoverDropdown label={getLabel()} popoverDropdownProps={popoverDropdownProps}>
        <SearchField
          name={`search-${fieldId}`}
          {...searchFieldProps}
          placeholder={searchBoxPlaceholder}
          css={searchFieldStyle}
        />
        <div css={buttonContainerStyle}>
          <TextButton css={textButtonStyle} onClick={selectAllHandler}>
            Select All
          </TextButton>
          <TextButton css={textButtonStyle} onClick={clearHandler}>
            Clear
          </TextButton>
        </div>
        <>
          {optionsToShow.length > 0 ? (
            <ChecklistField label="" {...fieldProps}>
              <Virtuoso
                style={{ height: getHeight(optionsToShow.length) }}
                increaseViewportBy={200}
                totalCount={optionsToShow.length}
                data={optionsToShow}
                itemContent={(index, option) => {
                  // Don't show options that don't have a value to select
                  if (!option.value) {
                    return null;
                  }

                  return (
                    <ChecklistField.Option
                      key={`${fieldId}-opt-${option.value}`}
                      name={option.value}
                      css={getListItemStyle({
                        isFirst: index === 0,
                        isLast: index === optionsToShow.length - 1,
                      })}
                    >
                      {option.label}
                    </ChecklistField.Option>
                  );
                }}
              />
            </ChecklistField>
          ) : (
            <Text>No matching {dataKey || 'results'} found!</Text>
          )}
        </>
      </PopoverDropdown>
      {fieldProps.error && (
        <Text color="error" size="medium">
          {fieldProps.error}
        </Text>
      )}
    </div>
  );
};
