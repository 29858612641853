import { FC } from 'react';
import {
  FormRow,
  TextField,
  SearchField,
  useFormField,
  BannerNotification,
} from '@weave/design-system';
import { bannerStyle } from '../../../../../payment-fields.styles';

const DummyAch: FC = () => {
  const emailProps = useFormField({ type: 'text' });
  const fullNameProps = useFormField({
    type: 'text',
    placeholder: 'First and last name',
  });
  const bankAccountProps = useFormField({
    type: 'text',
  });

  return (
    <>
      <BannerNotification
        status="info"
        message="This is just a preview of the fields"
        css={bannerStyle}
      />
      <form>
        <FormRow>
          <TextField {...emailProps} name="email" hasNonFloatingLabel label="Email" />
        </FormRow>
        <FormRow>
          <TextField
            {...fullNameProps}
            name="full-name"
            hasNonFloatingLabel
            label="Full name"
          />
        </FormRow>
        <FormRow>
          <SearchField
            {...bankAccountProps}
            name="bank-account"
            label="Search for your bank"
          />
        </FormRow>
      </form>
    </>
  );
};

export default DummyAch;
