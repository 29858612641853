import { FC, useMemo } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { StripeElementsOptions } from '@stripe/stripe-js';
import { Modal, ModalControlResponse } from '@weave/design-system';
import useStore from '@forms-exp/store';
import { useEnvironmentVariablesContext } from '@forms-exp/env';
import PaymentAch from './payment-ach/payment-ach.component';
import { modalStyle, stripeAppearance } from '../../../payment-fields.styles';

export interface PaymentAchModalCommonProps {
  fieldId: string;
  label: string;
  error: string;
}

interface PaymentAchModalProps extends PaymentAchModalCommonProps {
  modalControls: ModalControlResponse;
}

const PaymentAchModal: FC<PaymentAchModalProps> = ({ fieldId, label, modalControls }) => {
  const { stripe, stripeAccountId } = useStore();
  const { environmentVariables } = useEnvironmentVariablesContext();

  const elementsOptions = useMemo<StripeElementsOptions>(() => {
    return {
      mode: 'setup',
      appearance: stripeAppearance,
      currency: 'usd',
      setupFutureUsage: 'off_session',
      paymentMethodConfiguration: environmentVariables.stripeACHOnlyPaymentMethodConfig,
      on_behalf_of: stripeAccountId,
      paymentMethodOptions: {
        us_bank_account: { verification_method: 'instant' as const },
      },
    };
  }, [environmentVariables.stripeACHOnlyPaymentMethodConfig, stripeAccountId]);

  return (
    <Modal {...modalControls.modalProps} css={modalStyle} disableCloseOnOverlayClick>
      <Modal.Header>{label}</Modal.Header>
      <Elements
        key={environmentVariables.stripeKey}
        stripe={stripe}
        options={elementsOptions}
      >
        <PaymentAch
          fieldId={fieldId}
          onAchSaved={modalControls.closeModal}
          onCancel={modalControls.closeModal}
        />
      </Elements>
    </Modal>
  );
};

export default PaymentAchModal;
