import { usePopoverDialog } from '@weave/design-system';
import PopoverDropdown from './popover-dropdown.component';

interface UsePopoverDropdownResults {
  popoverDropdownProps: ReturnType<typeof usePopoverDialog>;
  PopoverDropdown: typeof PopoverDropdown;
}

export const usePopoverDropdown = (): UsePopoverDropdownResults => {
  const popoverDialogProps = usePopoverDialog({
    placement: 'bottom-start',
  });

  return {
    popoverDropdownProps: popoverDialogProps,
    PopoverDropdown,
  };
};
