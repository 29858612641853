import { css } from '@emotion/core';
import { theme } from '@weave/theme';

export const containerStyle = css`
  position: relative;
`;

export const triggerStyle = css`
  justify-content: start;
  align-items: center;
  padding: ${theme.spacing(2)};
  gap: ${theme.spacing(2)};

  .end-adornment {
    margin-left: auto;
  }
`;

export const dialogStyle = css`
  width: 100%;
  position: absolute;
  background-color: ${theme.colors.white};
  box-shadow: ${theme.shadows.heavy};
  z-index: ${theme.zIndex.popover};
  padding: ${theme.spacing(1, 3)};
  border-radius: ${theme.borderRadius.small};
  overflow: hidden;
`;
