import { FC } from 'react';
import { Text } from '@weave/design-system';
import useStore from '@forms-exp/store';
import { AchOnFileFieldState } from '@forms-exp/types';
import { PaymentAchModalCommonProps, usePaymentCardModal } from './hooks';
import AchPreview from './ach-preview/ach-preview.component';
import AddPaymentMethodPlaceholder from '../add-payment-method-placeholder-button/add-payment-method-placeholder-button.component';

interface AchOnFileFieldProps extends PaymentAchModalCommonProps {
  reviewMode?: boolean;
}

export const AchOnFileField: FC<AchOnFileFieldProps> = ({
  reviewMode = false,
  ...otherProps
}) => {
  const { fields } = useStore();
  const { PaymentAchModal, paymentAchModalControls } = usePaymentCardModal();
  const achOnFileField = fields[otherProps.fieldId] as AchOnFileFieldState;

  if (achOnFileField.value.lastFour || reviewMode) {
    return <AchPreview fieldId={otherProps.fieldId} reviewMode={reviewMode} />;
  }

  return (
    <>
      <AddPaymentMethodPlaceholder
        headingLabel={otherProps.label}
        buttonLabel="Add ACH on File"
        onClick={paymentAchModalControls.openModal}
      />
      {achOnFileField.error && (
        <Text color="error">Please add your ACH details to proceed further</Text>
      )}
      <PaymentAchModal modalControls={paymentAchModalControls} {...otherProps} />
    </>
  );
};

export default AchOnFileField;
