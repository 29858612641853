import { FC } from 'react';
import {
  usePopoverDialog,
  SecondaryButton,
  CaretDownIconSmall,
  CaretUpIconSmall,
} from '@weave/design-system';
import { triggerStyle, containerStyle, dialogStyle } from './popover-dropdown.styles';

interface PopoverDropdownProps {
  children: React.ReactNode;
  popoverDropdownProps: ReturnType<typeof usePopoverDialog>;
  label: string | JSX.Element;
}

const PopoverDropdown: FC<PopoverDropdownProps> = ({
  children,
  popoverDropdownProps,
  label,
}) => {
  // @ts-ignore "ref" does exist on the object
  const { ref } = popoverDropdownProps.getDialogProps();

  function showLabel() {
    if (typeof label === 'string') {
      return <span>{label}</span>;
    }

    return label;
  }

  return (
    <div css={containerStyle}>
      <SecondaryButton {...popoverDropdownProps.getTriggerProps()} css={triggerStyle}>
        {showLabel()}

        {popoverDropdownProps.isOpen ? (
          <CaretUpIconSmall className="end-adornment" />
        ) : (
          <CaretDownIconSmall className="end-adornment" />
        )}
      </SecondaryButton>

      {/* Had to implement a custom solution as the PopoverDialog component from the 
      design system was not working properly with page scroll. We are not able to update the DS version because of 
      issues with NextJS. */}
      {popoverDropdownProps.isOpen && (
        <div ref={ref} css={dialogStyle}>
          {children}
        </div>
      )}
    </div>
  );
};

export default PopoverDropdown;
