import { Appearance } from '@stripe/stripe-js';
import { css } from '@emotion/core';
import { theme } from '@weave/theme';

export const stripeAppearance: Appearance = {
  theme: 'stripe',
  rules: {
    '.Label--floating': {
      fontSize: theme.fontSize(12),
    },
    '.Input': {
      border: `1px solid ${theme.colors.neutral30}`,
      color: theme.colors.black,
    },
    '.Input:focus': {
      borderColor: 'var(--colorPrimary)',
      boxShadow: '0 0 0 1px var(--colorPrimary)',
    },
    '.Input--invalid': {
      borderColor: 'var(--colorDanger)',
      boxShadow: '0 0 0 1px var(--colorDanger)',
    },
  },
  variables: {
    colorText: theme.colors.neutral50,
    colorPrimary: theme.colors.primary50,
    colorDanger: theme.colors.critical50,
    fontSizeSm: theme.fontSize(16),
    fontFamily: theme.font.family,
  },
};

export const modalStyle = css`
  min-height: min(100vh, 600px);
`;

export const bannerStyle = css`
  margin: ${theme.spacing(2, 0)};
`;

export const previewStyles = {
  containerStyle: css`
    display: flex;
    align-items: center;
    gap: ${theme.spacing(2)};
    border: 1px solid ${theme.colors.neutral20};
    border-radius: ${theme.borderRadius.medium};
    padding: ${theme.spacing(1, 2)};
    margin-top: ${theme.spacing(1)};
  `,
  imageContainerStyle: css`
    max-width: 100%;
    display: flex;
    align-items: center;
    white-space: pre;
    flex-wrap: wrap;
  `,
  imageStyle: css`
    width: ${theme.spacing(5)};
  `,
  textStyle: css`
    margin: 0;
  `,
  brandTextStyle: css`
    margin: 0;
    text-transform: capitalize;
  `,
  bannerStyles: css`
    margin-top: ${theme.spacing(2)};
  `,
};
