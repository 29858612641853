import { FC, useEffect, useState } from 'react';
import {
  Text,
  DropdownField,
  useFormField,
  useDebouncedValue,
  SearchField,
} from '@weave/design-system';
import { FormFieldOptions } from '@forms-exp/types';
import { SerializedStyles } from '@emotion/core';
import { searchFieldStyle } from './dropdown-field.styles';

const DEFAULT_OPTIONS: FormFieldOptions[] = [];

interface CustomDropdownFieldProps {
  fieldProps: any; // TODO: Add proper type
  label: string;
  options: FormFieldOptions[] | undefined;
  fieldId: string;
  fieldStyle: SerializedStyles | null;
}

const CustomDropdownField: FC<CustomDropdownFieldProps> = ({
  fieldProps,
  label,
  options = DEFAULT_OPTIONS,
  fieldId,
  fieldStyle,
}) => {
  const [optionsToShow, setOptionsToShow] = useState<FormFieldOptions[]>([]);
  const searchFieldProps = useFormField({
    type: 'text',
  });
  const debouncedSearchValue = useDebouncedValue(searchFieldProps.value);
  const shouldShowSearchField = options?.length > 10;

  // Filter options based on search value
  useEffect(() => {
    if (debouncedSearchValue) {
      const filteredOptions = options?.filter(({ label }) => {
        // Don't show options that don't have a label when searching
        if (!label) {
          return false;
        }

        return label.toLowerCase().includes(debouncedSearchValue.toLowerCase());
      });
      setOptionsToShow(filteredOptions);
    } else {
      setOptionsToShow(options);
    }
  }, [debouncedSearchValue, options]);

  return (
    <div>
      <Text weight="regular">{label}</Text>

      <div>
        <DropdownField label="" {...fieldProps} css={fieldStyle}>
          {shouldShowSearchField && (
            <SearchField
              name={`search-${fieldId}`}
              {...searchFieldProps}
              css={searchFieldStyle}
            />
          )}
          {optionsToShow.length > 0 ? (
            optionsToShow.map(({ label, value }) => {
              // Don't show options that don't have a value to select
              if (!value) {
                return null;
              }

              return (
                <DropdownField.Option key={value} value={value}>
                  {label}
                </DropdownField.Option>
              );
            })
          ) : (
            <Text>No matching results found!</Text>
          )}
        </DropdownField>
      </div>
    </div>
  );
};

export default CustomDropdownField;
