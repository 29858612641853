import { FC } from 'react';
import { Text, Heading, BannerNotification } from '@weave/design-system';
import useStore from '@forms-exp/store';
import { AchOnFileFieldState } from '@forms-exp/types';
import { getACHIcon } from '@forms-exp/utils';
import { previewStyles } from '../../payment-fields.styles';

interface AchPreviewProps {
  fieldId: string;
  reviewMode: boolean;
}

const AchPreview: FC<AchPreviewProps> = ({ fieldId, reviewMode }) => {
  const { fields, previewMode } = useStore();
  const field = fields[fieldId] as AchOnFileFieldState;

  if (!field) {
    return <Text>Field not found!</Text>;
  }

  const { lastFour, bankName, accountType } = field.value;
  const heading = <Heading level={3}>{field.label}</Heading>;

  if (previewMode) {
    return (
      <>
        {heading}
        <Text>ACH details will be displayed here.</Text>
      </>
    );
  }

  if (!lastFour) {
    return (
      <>
        {heading}
        <Text>No ACH added!</Text>
      </>
    );
  }

  return (
    <>
      {heading}
      <div css={previewStyles.containerStyle}>
        <div css={previewStyles.imageContainerStyle}>
          <img src={getACHIcon()} alt="ACH Icon" css={previewStyles.imageStyle} />
        </div>
        <div>
          <Text weight="bold" css={previewStyles.brandTextStyle}>
            {bankName} <Text as="span">{accountType} Account</Text>
          </Text>
          <Text weight="bold" css={previewStyles.textStyle}>
            *{lastFour}
          </Text>
        </div>
      </div>

      {!reviewMode && (
        <BannerNotification
          status="success"
          message="Your ACH details have been submitted successfully. Please proceed to fill and submit the rest of your form."
          css={previewStyles.bannerStyles}
        />
      )}
    </>
  );
};

export default AchPreview;
