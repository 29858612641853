import { FC } from 'react';
import { Text } from '@weave/design-system';
import useStore from '@forms-exp/store';
import { CardOnFileFieldState } from '@forms-exp/types';
import PaymentCardPreview from './payment-card-preview/payment-card-preview.component';
import { usePaymentCardModal, PaymentCardModalCommonProps } from './hooks';
import AddPaymentMethodPlaceholder from '../add-payment-method-placeholder-button/add-payment-method-placeholder-button.component';

interface CardOnFileFieldProps extends PaymentCardModalCommonProps {
  reviewMode?: boolean;
}

export const CardOnFileField: FC<CardOnFileFieldProps> = ({
  reviewMode = false,
  ...otherProps
}) => {
  const { fields } = useStore();
  const { PaymentCardModal, paymentCardModalControls } = usePaymentCardModal();
  const cardOnFileField = fields[otherProps.fieldId] as CardOnFileFieldState;

  if (cardOnFileField.value.lastFour || reviewMode) {
    return <PaymentCardPreview fieldId={otherProps.fieldId} reviewMode={reviewMode} />;
  }

  return (
    <>
      <AddPaymentMethodPlaceholder
        headingLabel={otherProps.label}
        buttonLabel="Add Card on File"
        onClick={paymentCardModalControls.openModal}
      />
      {cardOnFileField.error && (
        <Text color="error">Please add your card details to proceed further</Text>
      )}
      <PaymentCardModal modalControls={paymentCardModalControls} {...otherProps} />
    </>
  );
};
