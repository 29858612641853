import { useState, useCallback } from 'react';

import { EnvironmentVariables } from '@forms-exp/types';
import { setClientSideEnv } from './environment';

export interface UseEnvironmentVariablesResult {
  isClientSideEnvReady: boolean;
  environmentVariables: EnvironmentVariables;
  updateEnvironmentVariables: (value: EnvironmentVariables) => void;
}

export const useEnvironmentVariables = (): UseEnvironmentVariablesResult => {
  const [isClientSideEnvReady, setIsClientSideEnvReady] = useState(false);
  const [environmentVariables, setEnvironmentVariables] = useState<EnvironmentVariables>({
    baseApiUrl: '',
    adobeJSApiKey: '',
    environment: '',
    sentryDSN: '',
    stripeKey: '',
    stripeCardOnlyPaymentMethodConfig: '',
    stripeACHOnlyPaymentMethodConfig: '',
  });

  const updateEnvironmentVariables = useCallback(
    (value: EnvironmentVariables) => {
      if (isClientSideEnvReady) {
        return;
      }

      setClientSideEnv(value);
      setEnvironmentVariables(value);
      setIsClientSideEnvReady(true);
    },
    [isClientSideEnvReady]
  );

  return {
    isClientSideEnvReady,
    environmentVariables,
    updateEnvironmentVariables,
  };
};
